import "./Tile.scss";
import React from "react";
import { IonCol, IonGrid, IonImg, IonRow, IonThumbnail } from "@ionic/react";

export interface ITile {
  title: string;
  icon: string;
  technologies: Array<string>;
  href: string;
  id: string;
}

const Tile = function Tile(props: ITile) {
  const { title, icon, technologies, id, href } = props;
  return (
    <div className="container" key={id}>
      <IonGrid>
        <IonRow className="main-row">
          <IonCol id="thumbnail-col" className="main-column">
            <IonThumbnail id="icon" style={{ objectFit: "cover" }}>
              <IonImg src={icon} />
            </IonThumbnail>
          </IonCol>
          <IonCol id="info-column" className="main-column">
            <IonRow className="title-row">
              <p>{title}</p>
            </IonRow>
            <IonRow>
              <IonCol className="technologies">
                <p>
                  {`${technologies[0]}, ${technologies[1]}, ${technologies[2]}`}
                </p>
              </IonCol>
            </IonRow>
            <IonRow>
              <a href={href}>Repository</a>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Tile;
