import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import "./Home.scss";
import {
  arrowForwardOutline,
  homeOutline,
  filterCircleOutline,
} from "ionicons/icons";

import { initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions";

import Tile, { ITile } from "../components/Tile";

const Home = function Home() {
  const [tileArray, setTileArray] = useState(Array<ITile>());

  useEffect(() => {
    // Use public credentials to initialise firebase app
    const app = initializeApp({
      projectId: "jacobdsmith-81e81",
      apiKey: "AIzaSyDBMNmfzGp7LwfFiuup9tb1lXFauz-ebuA",
      authDomain: "jacobdsmith-81e81.firebaseapp.com",
    });
    // Get cloud functions
    const functions = getFunctions(app);
    const getProjects = httpsCallable(functions, "getProjects");
    // Get projects using cloud function and set tileArray
    getProjects({})
      .then((response: HttpsCallableResult<unknown>) => {
        const projects: Array<ITile> = response.data as Array<ITile>;
        setTileArray(projects);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  async function handleScroll(yPos: number, yDelta: number) {
    // yDelta < 0 when moving uo
    // y Delta > 0 when moving down

    const elem = document.getElementById("page-container");

    // the ion content has its own associated scrollElement
    const scrollElement = await (elem as any).getScrollElement();
    const totalContentHeight = scrollElement.scrollHeight;

    console.log(yPos, yDelta, totalContentHeight);

    // const footerSpinning = [
    //   { transform: "rotate(0) scale(1)" },
    //   { transform: "rotate(360deg) scale(0)" },
    // ];

    // const footerTiming = {
    //   duration: 2000,
    //   iterations: 1,
    // };

    // if (yPos > 600) {
    //   const footer = document.querySelector("#footer");
    //   footer?.animate(footerSpinning, footerTiming);
    // }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="" />
            <IonButton>
              <IonIcon slot="start" icon={homeOutline} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton>
              <IonIcon slot="start" icon={arrowForwardOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        fullscreen
        scrollY={false}
        className="page-container"
        id="page-container"
      >
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton>
                <IonIcon slot="start" icon={filterCircleOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          scrollEvents
          onIonScroll={(e) => {
            handleScroll(e.detail.currentY, e.detail.deltaY);
          }}
          className="background"
        >
          <IonGrid className="tile-grid">
            <IonRow className="ion-justify-content-center grid-holder">
              {tileArray.map((tile) => {
                return (
                  <IonRow key={tile.id} className="tile-row">
                    {Tile(tile)}
                  </IonRow>
                );
              })}
            </IonRow>
            <IonRow />
          </IonGrid>
        </IonContent>
      </IonContent>
      <IonFooter id="footer">
        <IonToolbar>
          <IonTitle>© Copyright 2022</IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default Home;
